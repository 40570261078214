export const state = () => ({
  locale: 'en-GB',
})

export const mutations = {}

export const actions = {}

export const getters = {
  locale(state) {
    return state.locale
  },
}

export const store = () => ({
  state,
  mutations,
  actions,
  getters,
})