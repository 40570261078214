export default {
  paybylinkPage: {
    paybylink: 'Pay By Link',
    paybylink2: 'You are paying securely with paybylink',
    fillData: 'Review Personal Details',
    createCheckout: 'Enter Card Details',
    createCheckoutButton: 'Continue',
    confirmData: 'Pay with Paybylink',
    channel: 'Channel',
    payNow: 'Pay Now',
    email: 'Email Address',
    amount: 'Amount',
    currency: 'Currency',
    reference: 'Reference',
    postCode: 'Post Code',
    recurringType: 'Recurring Type',
    recurringTypes: {
      none: 'None',
    },
    creditCard: 'Credit Card',
    cardNo: 'Card Number',
    expiry: 'Expiry',
    cvv: 'CVV',
    cardHolder: 'Card Holder',
    cancel: 'Cancel',
    productDetails: 'Product details',
  },
  forms: {
    emailInputLabel: 'Email Address',
    passwordInputLabel: 'Password',
    nameInputLabel: 'Name',
    confirmPasswordInputLabel: 'Confirm Password',
    fieldRequired: 'This field is required',
    fieldValueOutOfRange: 'Value out of range',
    required: 'Required',
    emailInvalid: 'Incorrect e-mail address',
    senderIdTwilioInvalid: 'Only letters, numbers, spaces allowed',
    amountInvalid: 'Invalid amount',
    expiryDateInvalid: 'Invalid expiry date',
    cvvInvalid: 'Invalid CVV',
    creditCardNoInvalid: 'Invalid credit card number',
  },
  dialogs: {
    paybylink: {
      successTitle: 'Transaction successful',
      successSubtitle: 'Your transaction has been sent successfully',
      failureTitle: 'Transaction failed',
      failureSubtitle: 'Your transaction has not been successful',
      unknownTitle: 'Payment status is unknown',
    },
    gotIt: 'Got it',
    tryAgain: 'Try Again',
    reference: 'Reference',
    amount: 'Amount',
  },
  datatables: {
    headers: {
      product: 'Product',
      quantity: 'Quantity',
      subtotal: 'Subtotal',
    },
  },
}
