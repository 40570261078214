import IconETerminal from '~/components/icon/e-terminal'
import IconDocument from '~/components/icon/document'
import IconCheckAll from '~/components/icon/check-all'
import IconCopy from '~/components/icon/copy'
import IconClose from '~/components/icon/close'


const icons = {
  eTerminal: { component: IconETerminal },
  document: { component: IconDocument },
  checkAll: { component: IconCheckAll },
  copy: { component: IconCopy },
  clear: { component: IconClose },
}

export default icons
