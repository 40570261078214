
export default {
  name: 'EmptyLayout',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound:
        'Are you trying to pay using paybylink? Please make sure you have been redirected from your text message or email.',
      src: '/images/404-not-found.png',
    }
  },
}
