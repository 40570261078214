import { render, staticRenderFns } from "./close.vue?vue&type=template&id=4e6c96e1&"
import script from "./close.vue?vue&type=script&lang=js&"
export * from "./close.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@4.3.0_lodash@4.17._ziirscntm6ri7or2susu3mlgei/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports